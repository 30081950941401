































































import {Component} from 'vue-property-decorator';
import ContactsDirectory from '@/components/ContactsDirectory.vue';
import {Action, Getter, Mutation} from 'vuex-class';
import DialogCreateEditDepartment from '@/components/DialogCreateEditDepartment.vue';
import {applicationStore} from '@/store/modules/application';
import VcSwitch from '@/components/VCSwitch.vue';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {mixins} from 'vue-class-component';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import EmptyState from "@/components/EmptyState.vue";
import {tourStore} from "@/store/modules/tour";
import {profileStore} from "@/store/modules/profile";

@Component({
  name: 'directory-tree',
  components: {VcSwitch, DialogCreateEditDepartment, ContactsDirectory, EmptyState}
})
export default class DirectoryTree extends mixins(ContainerCalc) {
  visible: boolean = false;
  customerView: boolean = false;

  @Getter showCreateEditDepartment;
  @Getter directoryInEditMode;
  @Getter businessDirectory

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  get membersCount(): number {
    return directoryStore.directoryContactsCount;
  }

  get membersOnline(): number {
    return directoryStore.contactsOnline;
  }

  get isEmpty() {
    return !this.businessDirectory?.length
  }

  @Mutation setDirectoryEditMode;
  @Action createDepartment;
  @Action saveDirectoryChanges
  @Action discardDirectoryChanges

  active(contacts: any[]): number {
    return contacts && contacts.filter(item => {
      if (item.associate) {
        return item.associate.status && item.associate.status.online;
      }
      if (item.contacts) {
        return item.contacts.find(item => item.associate.status && item.associate.status.online) != null;
      }
      if (item.type === 3) {
        return this.active(item.contacts);
      }
      return false;
    }).length;
  }

  onSaveChanges() {
    this.saveDirectoryChanges()
  }

  onDiscardChanges() {
    this.discardDirectoryChanges()
  }

  created() {
    console.log('DirectoryTree :: created');
  }

  mounted() {
    this.calcHeight(this.$refs.directoryTreeContainer)
  }

  beforeDestroy() {
    console.log('DirectoryTree :: beforeDestroy');
  }
}
